import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/317f3503/src/components/layouts/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting started`}</h1>
    <p>{`This page contains a guide to all the prerequisites and requirements to work with ds-kit, both for designers and for developers.`}</p>
    <h2>{`Prerequisites for designers`}</h2>
    <p>{`If you plan to work with ds-kit as a designer, make sure that you have an updated copy of `}<a parentName="p" {...{
        "href": "https://www.sketchapp.com/"
      }}>{`Sketch`}</a>{` installed. We keep our templates simple, but often they make use of some features of Sketch that might not work on older versions.`}</p>
    <h2>{`Prerequisites for developers`}</h2>
    <p>{`If you are planning to work with the ds-kit code base, make sure you have the latest version of `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`Node.js`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/"
      }}>{`npm`}</a>{` installed.`}</p>
    <p>{`If you plan on contributing to the `}<a parentName="p" {...{
        "href": "https://github.com/datastory-org/ds-kit"
      }}>{`ds-kit repo`}</a>{`, take a moment to read through some of the previous `}<a parentName="p" {...{
        "href": "https://github.com/datastory-org/ds-kit/issues"
      }}>{`issues`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/datastory-org/ds-kit/pulls"
      }}>{`PRs`}</a>{`, as well as the official `}<a parentName="p" {...{
        "href": "https://github.com/datastory-org/ds-kit/blob/master/README.md"
      }}>{`readme`}</a>{` to learn how we work and contribute code.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      